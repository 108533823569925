import React from 'react'
import { Link } from 'gatsby'
import { css } from 'styled-components'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FooterIndex from '../../components/footerIndex'
import Quote from '../../components/quote'
import FranziImage from '../../components/franziImage'
import stepsImage from '../../images/steps.svg'
import focusImage from '../../images/focus.svg'
import { device } from '../../styles/devices'

const TeamFranziskaPage = () => (
  <Layout>
    <SEO title="Franziska Schacht" />

    <div
      className="grid"
      css={css`
        h3 {
          font-size: 3.2rem;
          margin-bottom: 20px;
        }
        @media ${device.laptop} {
          h3 {
            display: inline-block;
            position: relative;
            background: linear-gradient(0, #fff1c6 24px, transparent 0);
            &:before {
              content: ' ';
              position: absolute;
              bottom: 0;
              left: -18px;
              background-color: transparent;
              border-bottom: 24px solid transparent;
              border-right: 18px solid #fff1c6;
            }
            &:after {
              content: ' ';
              position: absolute;
              bottom: 0;
              background-color: transparent;
              border-top: 24px solid transparent;
              border-left: 18px solid #fff1c6;
            }
          }
        }

        h4 {
          font-family: 'Montserrat';
          font-weight: bold;
        }

        p {
          margin-bottom: 20px;
          line-height: 33.18px;
          letter-spacing: -0.004em;
        }

        ul {
          margin-bottom: 20px;
          list-style: disc;
          line-height: 33.18px;
        }

        li {
          margin-left: 2rem;
        }
      `}
    >
      <div className="grid-contained">
        <div
          css={css`
            & {
              margin-top: 1rem;
              margin-bottom: 3rem;
              font-size: 3rem;
              text-align: center;
            }
            @media ${device.laptop} {
              margin-top: 3rem;
              margin-bottom: 6rem;
              font-size: 4rem;
            }
          `}
        >
          <h2>Franziska Schacht</h2>
        </div>

        <div className="grid-contained">
          <Quote author="Galileo Galilei">
            Man kann einen Menschen nichts lehren; man kann ihm nur helfen, es
            in sich selbst zu finden.
          </Quote>
        </div>

        <div className="grid-contained">
          <div
            css={css`
              & {
                margin-top: 4rem;
              }
              @media ${device.laptop} {
                display: flex;
                justify-content: space-around;
                margin-top: 6rem;
              }
            `}
          >
            <div
              css={css`
                & {
                  margin-bottom: 4rem;
                  text-align: center;
                }
              `}
            >
              <FranziImage />
            </div>
            <div
              css={css`
                @media ${device.laptop} {
                  margin-left: 2rem;
                  max-width: 600px;
                }
              `}
            >
              <h3>Konfliktrezept bedeutet für mich</h3>
              <p>
                Geformt durch unsere Erfahrungen verhalten wir uns so, wie wir
                es gelernt haben - unser gefülltes Kochbuch mit den besten
                Rezepten immer dabei. Die noch leeren Seiten möchten wir füllen,
                mit neuen Erkenntnissen über uns selbst, möglichen anderen
                Perspektiven auf die Welt, um so vorhandene Potenziale
                freizusetzen.
              </p>
              <p>
                Neue Ziele stecken und neue Wege beschreiten, immer wieder neues
                ausprobieren und sich und seine Mitmenschen (neu)-entdecken.
              </p>
              <p>
                Während meines bisherigen Lebens probierte ich verschiedene
                Dinge aus. So arbeitete ich als Snowboardlehrerin, in einer
                Anwaltskanzlei, im Marketing und Vertrieb, als Projektmanagerin
                in einer Unternehmensberatung für IT und Management Consulting
                und nun als Mediatorin. Mein sprach- und
                kommunikationswissenschaftlich geprägtes Studium verbindet all
                diese Tätigkeiten miteinander.
              </p>
              <p>
                Denn egal in welcher Funktion ich unterwegs war, Lehrerin,
                Beraterin oder Mediatorin, ist für mich klar: Der Schlüssel zum
                “Erfolg” liegt in der Art und Weise, wie wir uns ausdrücken, die
                Welt wahrnehmen und mit anderen kommunizieren. Mit der richtigen
                Kommunikation begeistern wir Menschen von uns und unseren Ideen,
                räumen Missverständnisse beiseite und ebnen den Weg für ein
                konstruktives „Miteinander“.
              </p>
              <p>
                Ich beschäftige mich leidenschaftlich damit, wie wir uns besser
                „verstehen“ und begleite gemeinsam mit Ruth Nießen Menschen
                dabei, ihre persönlichen Fähigkeiten in diesem Feld
                weiterzuentwickeln.
              </p>
            </div>
          </div>
        </div>

        <div className="grid-contained">
          <div
            css={css`
              img {
                display: none;
              }

              @media ${device.laptop} {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-around;
                margin-top: 6rem;

                & > img {
                  display: block;
                  margin-left: 2rem;
                  width: 300px;
                  height: 685px;
                }
              }
            `}
          >
            <img src={focusImage} />
            <div
              css={css`
                @media ${device.laptop} {
                  max-width: 600px;
                }
              `}
            >
              <h3>Meine Schwerpunkte</h3>
              <h4>Sprache und Kommunikation</h4>
              <p>
                Durch mein Studium konnte ich mir in diesem Feld ein breites
                Spektrum an Wissen aneignen und in die vielschichtige Welt des
                zwischenmenschlichen Miteinanders eintauchen. Dieses Wissen
                möchte ich gerne mit Ihnen teilen.
              </p>
              <h4>Wirtschaftsmediation</h4>
              <p>
                Die unterschiedlichen Konfliktintensitäten und die Anzahl der
                Beteiligten entscheiden über die Herangehensweise. Grundsätzlich
                bedeutet jeder Konflikt, von klein bis groß, die Chance eine
                Veränderung herbeizuführen und neue Potenziale freizusetzen.
                Wandeln wir die negative Energie um und nutzen sie positiv.
              </p>
              <h4>Projekt-, Prozess- und Change Management</h4>
              <p>
                Meine Erfahrungen als Beraterin haben mich für die verschiedenen
                Herausforderungen im Umfeld dieser drei Bereiche sensibilisiert.
                Ob kommunikative, strukturelle oder systemische Gründe für eine
                Schieflage der aktuellen Situation verantwortlich sind, finden
                wir gemeinsam heraus.
              </p>
            </div>
          </div>
        </div>

        <div className="grid-contained">
          <div
            css={css`
              img {
                display: none;
              }
              @media ${device.laptop} {
                display: flex;
                justify-content: space-around;
                margin-top: 6rem;

                & > img {
                  display: block;
                  margin-right: 2rem;
                  width: 300px;
                  height: 497px;
                }
              }
            `}
          >
            <img src={stepsImage} />
            <div>
              <h3>Mein Hintergrund auf einen Blick</h3>
              <h4>Studium</h4>
              <ul>
                <li>Bachelor Sprache und Kommunikation</li>
                <li>Master Linguistik</li>
              </ul>

              <h4>Ausbildung</h4>
              <ul>
                <li>Mediatorin</li>
              </ul>

              <h4>Kenntnisse</h4>
              <ul>
                <li>Projektmanagement</li>
                <li>Marketing und Vertrieb</li>
                <li>Prozessmanagement</li>
              </ul>

              <h4>Branche</h4>
              <ul>
                <li>Industrie</li>
                <li>öffentlicher Sektor</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterIndex />
  </Layout>
)

export default TeamFranziskaPage
